import React, { useId, useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setCurrentStep,
  setPaymentDetails,
  setReceivingMerchantCode,
  setReceivingMerchantName,
} from "../slices/merchant-payment-slice";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { showToast } from "../slices/toast-slice";
import { hideLoading, showLoading } from "../slices/loading-slice";
import GlobalConfirmPasscod from "./GlobalConfirmPasscod";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

const typesOptions = [
  { label: "Moto", amount: 30000.0 },
  { label: "Tricycle", amount: 45000.0 },
  { label: "Taxi-urbain", amount: 100000.0 },
  { label: "Taxi-interurbain", amount: 120000.0 },
  { label: "Mini-bus", amount: 130000.0 },
  { label: "Bus", amount: 140000.0 },
  { label: "Camion", amount: 160000.0 },
  { label: "Citerne", amount: 160000.0 },
  { label: "Semi-remorque", amount: 160000.0 },
];

export default function PayDSDPortiere() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const id = useId();
  const authUser = useAuthUser();
  const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
  const merchantName = useSelector(
    (state) => state.merchantPayment.receiving_merchant_name
  );
  const rcvMerchantCode = useSelector(
    (state) => state.merchantPayment.receiving_merchant_code
  );
  const current_step = useSelector(
    (state) => state.merchantPayment.current_step
  );

  const [selectedType, setSelectedType] = useState("");
  const [paymentAmt, setPaymentAmount] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  var schemaObject = {};
  schemaObject["firstName"] = yup.string().required(t("FIELD_REQUIRED"));
  schemaObject["lastName"] = yup.string().required(t("FIELD_REQUIRED"));
  schemaObject["types"] = yup.string().required(t("FIELD_REQUIRED"));
  schemaObject["telephone"] = yup.string().required(t("FIELD_REQUIRED"));
  schemaObject["amount"] = yup.string();
  schemaObject["reference"] = yup.string().required(t("FIELD_REQUIRED"));
  const schemaSave = yup.object().shape(schemaObject);
  var {
    control,
    register,
    handleSubmit: handleSubmitSave,
    formState: { errors },
    trigger,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schemaSave),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  var schemaSearchObject = {};
  const schemaSearch = yup.object().shape(schemaSearchObject);
  var {
    control,
    register: registerSearch,
    handleSubmit: handleSubmitSearch,
    formState: { errors: errorsSearch },
    trigger,
    setValue: seValueSearch,
    reset,
  } = useForm({
    resolver: yupResolver(schemaSearch),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleTypeChange = (e) => {
    const selected = typesOptions.find((type) => type.label === e.target.value);
    setSelectedType(selected ? selected.label : "");
    setPaymentAmount(selected ? selected.amount : 0);
  };

  const handleMerchantPayment = (data) => {
    if (globalAuthUser?.location?.agent_country?.enable_passcode === "1") {
      openModal();
      return;
    }
    // save the merchant payment
    saveMerchantPayment(data);
  };

  const saveMerchantPayment = (data) => {
    console.log("onSavePayment clicked", data);
    const countryDetails = globalAuthUser.location.agent_country;
    axios({
      method: "post",
      url:
      process.env.REACT_APP_BNB_BASE_URL + "/api/portal/merchant/transaction",
      withCredentials: false,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${authUser().tokenType} ${authUser().token}`,
      },
      data: {
        type: "cashin",
        channel: "CDT",
        amount: paymentAmt,
        currency: countryDetails?.currency,
        phone_number:
          "+" +
          globalAuthUser?.location?.agent_country.phone_code +
          data.telephone,
        telephone:
          "+" +
          globalAuthUser?.location?.agent_country.phone_code +
          data.telephone,
        request_type: 0, // basic request type
        country_code: countryDetails?.isoname,
        request_by: 0,
        user_id: globalAuthUser?.user_id,
        first_name: data.firstName,
        last_name: data.lastName,
        numero_portiere: data.types,
        reference: data.reference,
        customer_id: data.reference,
        rcv_merchant_code: rcvMerchantCode,
      },
    })
      .then((res) => {
        dispatch(hideLoading());
        if (res.data.status == 1) {
          // update the payment details
          dispatch(setPaymentDetails(res?.data?.data));
  
          // show the success message to the caller
          dispatch(
            showToast(["success", "Successful Payment", t(res?.data?.message)])
          );
  
          // update the current step
          dispatch(setCurrentStep(current_step + 1));
        } else {
          if (res?.data?.message.includes("This reference")) {
            dispatch(showToast(["danger", "", t("REFERENCE_EXISTS", { reference: data.reference })]));
          } else {
            dispatch(showToast(["danger", "", t(res?.data?.message)]));
          }
        }
      })
      .catch((error) => {
        dispatch(hideLoading());
        
        if (error.response?.data?.message != undefined) {
          dispatch(showToast(["danger", "", t(error.response?.data?.message)]));
        } else {
          dispatch(
            showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")])
          );
        }
      });
  };
  

  const handleCancel = () => {
    // reset the form fields
    const paymentForm = document.getElementById("paymentForm");
    paymentForm.reset();

    // empty the other stored data
    dispatch(setCurrentStep(0));
    dispatch(setReceivingMerchantCode(""));
    dispatch(setReceivingMerchantName(""));
    dispatch(setPaymentDetails({}));

    // navigate to the dashboard
    navigate("/home");
  };

  return (
    <>
      <div style={{ marginBottom: "40px" }}>
        <hr />
        <span> {t("MERCHANT_NAME")} </span>:{" "}
        <span
          style={{
            textTransform: "uppercase",
            fontSize: "18px",
            fontFamily: "verdana",
            fontWeight: "bold",
          }}
        >
          {" "}
          {merchantName}{" "}
        </span>
        <hr />
      </div>

      <div className="row mb-2">
        <div className="col-12 col-lg-2"></div>
        <div className="col-12 col-lg-8">
          <div className="mb-2">
            <form
              id="paymentForm"
              onSubmit={handleSubmitSave(handleMerchantPayment)}
            >
              {/** Types Dropdown */}
              <div className="mb-3">
                <label htmlFor="types">
                  {t("TYPE")}
                  <span className="text-danger">*</span>
                </label>
                <select
                  {...register("types")}
                  className="form-select"
                  aria-label="Default select example"
                  name="types"
                  onChange={handleTypeChange}
                >
                  <option value="">{t("SELECT_A_TYPE")}</option>
                  {typesOptions.map((type, index) => (
                    <option key={index} value={type.label}>
                      {type.label}
                    </option>
                  ))}
                </select>
                {errors.types && (
                  <div>
                    <small className="text-danger mt-1 ">
                      {errors.types.message}
                    </small>
                  </div>
                )}
              </div>

              {/** First Name */}
              <div className="mb-3">
                <label htmlFor="firstName">
                  {t("FIRST_NAME")}
                  <span className="text-danger">*</span>
                </label>
                <input
                  {...register("firstName")}
                  type="text"
                  className="form-control"
                  id="firstName"
                  name="firstName"
                />
                {errors.firstName && (
                  <div>
                    <small className="text-danger mt-1">
                      {errors.firstName.message}
                    </small>
                  </div>
                )}
              </div>

              {/** Last Name */}
              <div className="mb-3">
                <label htmlFor="lastName">
                  {t("LAST_NAME")}
                  <span className="text-danger">*</span>
                </label>
                <input
                  {...register("lastName")}
                  type="text"
                  className="form-control"
                  id="lastName"
                  name="lastName"
                />
                {errors.lastName && (
                  <div>
                    <small className="text-danger mt-1">
                      {errors.lastName.message}
                    </small>
                  </div>
                )}
              </div>

              {/** Telephone */}
              <div className="mb-3">
                <label htmlFor="telephone">
                  {t("TELEPHONE")}
                  <span className="text-danger">*</span>
                </label>
                <div className="input-group col-8" style={{ width: "100%" }}>
                  <span className="input-group-text" id="basic-addon1">
                    {" "}
                    {globalAuthUser?.location?.agent_country.phone_code}{" "}
                  </span>
                  <input
                    aria-describedby="basic-addon1"
                    {...register("telephone")}
                    type="text"
                    className="form-control"
                    id="telephone"
                    name="telephone"
                  />
                </div>

                {errors.telephone && (
                  <div>
                    <small className="text-danger mt-1">
                      {errors.telephone.message}
                    </small>
                  </div>
                )}
              </div>

              {/** Amount */}
              <div className="mb-3">
                <label htmlFor="amount">
                  {t("AMOUNT")}
                  <span className="text-danger">*</span>
                </label>
                <input
                  {...register("amount")}
                  type="text"
                  className="form-control"
                  id="amount"
                  name="amount"
                  value={paymentAmt}
                  readOnly
                />
                {errors.amount && (
                  <div>
                    <small className="text-danger mt-1">
                      {errors.amount.message}
                    </small>
                  </div>
                )}
              </div>

              {/** Reference */}
              <div className="mb-3">
                <label htmlFor="reference">
                  {t("REFERENCE")}
                  <span className="text-danger">*</span>
                </label>
                <input
                  {...register("reference")}
                  type="text"
                  className="form-control"
                  id="reference"
                  name="reference"
                />
                {errors.reference && (
                  <div>
                    <small className="text-danger mt-1">
                      {errors.reference.message}
                    </small>
                  </div>
                )}
              </div>

              {/** Save and Cancel Buttons */}
              <div className="mb-3 d-flex justify-content-between">
                <button
                  className="btn btn-primary"
                  type="submit"
                  onClick={handleSubmitSave(handleMerchantPayment)}
                >
                  {t("SAVE")}
                </button>
                <button
                  className="btn btn-secondary"
                  type="button"
                  onClick={handleCancel}
                >
                  {t("CANCEL")}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="col-12 col-lg-2"></div>
      </div>

      {/* Modal for Passcode Confirmation */}
      {isModalOpen && (
        <GlobalConfirmPasscod
          onClose={closeModal}
          onConfirm={() => {
            // Confirm passcode and save the payment
            saveMerchantPayment();
            closeModal();
          }}
        />
      )}
    </>
  );
}

import { useDispatch, useSelector } from "react-redux";
import "./App.css";
import SideMenu from "./layout/SideMenu";
import "bootstrap-icons/font/bootstrap-icons.css";
import "boxicons/css/boxicons.css";
import "flag-icon-css/css/flag-icons.min.css";
import Login from "./components/auth/Login";
import PaidTransaction from "./components/PaidTransaction";
import PayTransaction from "./components/PayTransaction";
import PayTxnConfirmation from "./components/PayTxnConfirmation";
import PayCompleted from "./components/PayCompleted";
import Step1 from "./components/MoneyGram/Receive/RcvStep1";
import RcvProcess from "./components/MoneyGram/Receive/RcvProcess";
import Header from "./layout/Header";
import Content from "./layout/Content";
import i18next from "i18next";
import { Route, Link, BrowserRouter as Router, Routes, Navigate } from "react-router-dom";
// import {useIsAuthenticated} from 'react-auth-kit';
import { RequireAuth, useAuthUser, useSignIn, useSignOut } from "react-auth-kit";
import { useEffect } from "react";
import axios from "axios";
import { login, setGlobalAuthUser, set_LANGUAGE } from "./slices/auth-slice";
import RefNoDetails from "./components/MoneyGram/Receive/RefNoDetails";
import MoneygramHome from "./components/send-money/moneygram/MoneygramHome";
import RiaHome from "./components/send-money/ria/RiaHome";
import CustomToast from "./components/CustomToast";
import CustomLoading from "./components/CustomLoading";
import { useTranslation } from "react-i18next";
import SendMoney from "./components/send-money/home/SendMoney";
import SendMoneyDetails from "./components/send-money/home/SendMoneyDetails";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import PaidTxnIndex from "./components/paidTransaction/txnIndex";
import PaidTxnShow from "./components/paidTransaction/txnShow";
import SubmitPaidTransation from "./components/paidTransaction/create";
import MoneygramFormFreeSendHome from "./components/send-money/moneygram/form-free/MoneygramFormFreeSendHome";
import FormFreeDetails from "./components/MoneyGram/Receive/FormFreeDetails";
import RiaCompleted from "./components/send-money/ria/RiaCompleted";
import SendTransaction from "./components/SendTransction";
import NewPassword from "./components/auth/NewPassword";
import ForgotPassword from "./components/auth/ForgotPassword";
import AccountStatement from "./components/accountStatement"
import SecurityCode from "./components/auth/SecurityCode";
import BnbHome from "./components/send-money/bnb/BnbHome";
import BnbCompleted from "./components/send-money/bnb/BnbCompleted";
import UserProfile from "./components/userProfile/userProfile";
import VerifyEmail from "./components/userProfile/verifyEmail";
import ChangeEmail from "./components/userProfile/changeEmail";
import ValidateCode from "./components/userProfile/validateCode";
import CreatePasscode from "./components/userPasscode/create-passcode";
import ActivatePasscode from "./components/userPasscode/activatePasscode";
import ChangePassword from "./components/userPassword/changePassword";
import RepaymentRequest from "./components/repayment-requests";
import RepaymentDetails from "./components/repayment-requests/show";
import CashPickupRepayment from "./components/repayment-requests/cashPickup";
import BankRepayment from "./components/repayment-requests/bankRepayment";
import WalletRepayment from "./components/repayment-requests/walletRepayment";
import CreateManualCashin from "./components/mobileMoney/orangeMoney/create";
import MobileMoney from "./components/mobileMoney";
import ManualCashinDetails from "./components/mobileMoney/show";
import LocalHome from "./components/local_sending/LocalHome";
import AgentRepayment from "./components/agentRepayment";
import AgentRepaymentDetails from "./components/agentRepayment/show";
import AgentTopup from "./components/accountStatement/agentTopup";
import Multiservice from "./components/multiservices";
import MultiserviceCreate from "./components/multiservices/create";
import MerchantPaymentForm from "./components/merchantPayment/MerchantPaymentForm";
import MerchantPaymentReport from "./components/merchantPayment/MerchantPaymentReport";
import MerchantPaymentShow from "./components/merchantPayment/MerchantPaymentShow";
import MultiserviceDetails from "./components/multiservices/show";
import AgentRegistrationForm from "./components/AgentRegistration/AgentRegistrationForm";
import CreateMtnCashin from "./components/mobileMoney/mtnMomo/cashin";
import CreateMtnCashout from "./components/mobileMoney/mtnMomo/cashout";
import ValidateRequest from "./components/validationRequests/view";
import CreateValidationRequest from "./components/validationRequests/create";
import EcobankXpress from "./components/xpress";
import EcobankXpressDetails from "./components/xpress/show";
import CreateXpressCashin from "./components/xpress/create";
import ValidationIndex from "./components/validationRequests";
import PayDSD from "./components/PayDSD";
import SummaryReports from "./components/summaryReports";
import CommissionReport from "./components/summaryReports/commission/commissionReport";
import CommissionDetail from "./components/summaryReports/commission/detail";
import CommissionSummary from "./components/summaryReports/commission/summary";
import LocationSummary from "./components/summaryReports/location/index.jsx";
import CompanyReports from "./components/summaryReports/company";

import CreateCashin from "./components/mobileMoney/bnb/cashin";
import CreateCashout from "./components/mobileMoney/bnb/cashout";

import TellerAccessIndex from "./components/tellerAccess/TellerAccessIndex";
import TellerAccessRequestForm from "./components/tellerAccess/TellerAccessRequestForm";
import TellerAccessRequestEdit from "./components/tellerAccess/tellerAccessComps/TellerAccessRequestEdit";
import AuthMiddleware from "./services/authMiddleware";
function App() {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const isToastVisible = useSelector((state) => state.toast.isToastVisible);
  const isLoading = useSelector((state) => state.loading.isLoading);
  const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
  const signOut = useSignOut();
  // console.log(isLoggedIn);
  // const isAuthenticated = useIsAuthenticated()
  // console.log(isAuthenticated);

  const authUser = useAuthUser();
  const signIn = useSignIn();
  const dispatch = useDispatch();

  function getCookieValue(userKey) {
    let items = document.cookie.split(";")
    for (let item of items) {
      let [key, value] = item.split("=");
      if (key.includes(userKey))
        return value;
    };
    return null;
  }


  useEffect(() => {

    let langCookie = getCookieValue("i18next");

    if (langCookie != undefined && langCookie != null) {
      i18next.changeLanguage(langCookie);
      dispatch(set_LANGUAGE(langCookie));
    }

    if (authUser()) {
      console.log("token exist");
      axios({
        method: "post",
        url: process.env.REACT_APP_BNB_BASE_URL + "/api/portal/auth/loginWithToken",
        withCredentials: false,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: { token: authUser().token },
      }).then((res) => {
        const response = res;
        const status = response.data.status;
        if (status == 0) {
          //handle in case of refresh the page and the agent is automatically deactivated
          console.log(response.data.message);
          signOut();
          return <Navigate to="/login" replace />;
        }

        var $mToken = "Bearer " + res.data.data.access_token;

        signIn({
          token: res.data.data.access_token,
          expiresIn: res.data.data.expires_in,
          tokenType: "Bearer",
          authState: { token: res.data.data.access_token, tokenType: "Bearer" },
        });

        // 'Authorization': 'Bearer 7G42uOYOJcgyCKr21JBNPeFWrCs6hJPNdUxRipURI4BaEmz9MljlSuq6F9hVJQmAtwl91ONN5GPeVNcy'

        axios({
          method: "get",
          url: process.env.REACT_APP_BNB_BASE_URL + "/api/portal/userProfileWithData",
          withCredentials: false,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: $mToken,
          },
        }).then((res1) => {
          console.log(res1);
          const response = res1;
          const status = response.data.status;
          if (status == 0) {
            //handle in case of refresh the page and the agent is automatically deactivated
            console.log("Logout Inside userProfileWithData");
            signOut();
            return <Navigate to="/login" replace />;
          }
          dispatch(login());
          dispatch(setGlobalAuthUser(response.data.data));
        });
      });
    }
  }, []);

  const { t } = useTranslation();

  return (
    <div className="App">
      <Router>
        <>
          {!authUser() && <>
            {
              <Routes>
                <Route
                  path="/new-password"
                  element={
                    <NewPassword />
                  }
                />
                <Route
                  path="/forgot-password"
                  element={
                    <ForgotPassword />
                  }
                />
                <Route
                  path="/agent-registration"
                  element={
                    <AgentRegistrationForm />
                  }
                />
                <Route
                  path="/security-code/:id"
                  element={
                    <SecurityCode />
                  }
                />
                <Route
                  path="/login"
                  element={
                      <Login />
                  }
                />
                <Route
                  path="*"
                  element={<Login />}
                />
              </Routes>
            }
          </>}
          {authUser() && globalAuthUser && (
            <>
              {/* <div>{t("hello")}</div> */}
              <SideMenu />
              <section className="home-section">
                <div className="home-content" style={{ background: "white" }}>
                  <Header />
                </div>
                <Routes>
                  <Route
                    path="/home"
                    name="Home"
                    element={
                      <AuthMiddleware>
                        <RequireAuth loginPath={"/login"}>
                          {" "}
                          <Content />{" "}
                        </RequireAuth>
                      </AuthMiddleware>
                    }
                  />
                  <Route
                    path="/tellerAccess"
                    element={
                      <AuthMiddleware>
                        <RequireAuth loginPath={"/login"}>
                          {" "}
                          <TellerAccessIndex />{" "}
                        </RequireAuth>
                      </AuthMiddleware>
                    }
                  />
                  <Route
                    path="/creareTellerRequest"
                    element={
                      <AuthMiddleware>
                        <RequireAuth loginPath={"/login"}>
                          {" "}
                          <TellerAccessRequestForm />{" "}
                        </RequireAuth>
                    </AuthMiddleware>
                    }
                  />
                  <Route
                    path="/editTellerRequest/:requestId"
                    element={
                      <AuthMiddleware>
                        <RequireAuth loginPath={"/login"}>
                          {" "}
                          <TellerAccessRequestEdit />{" "}
                        </RequireAuth>
                      </AuthMiddleware>
                    }
                  />
                  <Route
                    path="/validateRequest"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <ValidationIndex />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />
                  <Route
                    path="/validateRequest/:reference"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <ValidateRequest />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />
                  <Route
                    path="/validateRequest/create"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <CreateValidationRequest />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />
                  <Route
                    path="/paidTransaction"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <PaidTransaction />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />
                  <Route
                    path="/payTransaction"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <PayTransaction />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />
                  <Route
                    path="/payDSD"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <PayDSD />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />
                  <Route
                    path="/sendTransaction"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <SendTransaction />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />

                  <Route
                    path="/sendLocalTransaction"
                    element={
                      <AuthMiddleware>
                        <RequireAuth loginPath={"/login"}>
                          {" "}
                          <LocalHome />{" "}
                        </RequireAuth>
                      </AuthMiddleware>
                    }
                  />

                  <Route
                    path="/PayTxnConfirmation"
                    element={
                      <AuthMiddleware>
                        <RequireAuth loginPath={"/login"}>
                          {" "}
                          <PayTxnConfirmation />{" "}
                        </RequireAuth>
                      </AuthMiddleware>
                    }
                  />
                  <Route
                    path="/payCompleted"
                    element={
                      <AuthMiddleware>
                        <RequireAuth loginPath={"/login"}>
                          {" "}
                          <PayCompleted />{" "}
                        </RequireAuth>
                      </AuthMiddleware>
                    }
                  />
                  <Route
                    path="/refNoDetails"
                    element={
                      <AuthMiddleware>
                        <RequireAuth loginPath={"/login"}>
                          {" "}
                          <RefNoDetails />{" "}
                        </RequireAuth>
                      </AuthMiddleware>
                    }
                  />
                  <Route
                    path="/formFreeDetails"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <FormFreeDetails />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />
                  <Route
                    path="/step1"
                    element={
                      <AuthMiddleware>
                        <RequireAuth loginPath={"/login"}>
                          {" "}
                          <Step1 />{" "}
                        </RequireAuth>
                      </AuthMiddleware>
                    }
                  />
                  <Route
                    path="/rcvProcess"
                    element={
                      <AuthMiddleware>
                        <RequireAuth loginPath={"/login"}>
                          {" "}
                          <RcvProcess />{" "}
                        </RequireAuth>
                      </AuthMiddleware>
                    }
                  />
                  <Route
                    path="/send-money"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <SendMoney />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />
                  <Route
                    path="/send-money/:reference"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <SendMoneyDetails />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />
                  <Route
                    path="/send-money/moneygram"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <MoneygramHome />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />
                  <Route
                    path="/send-money/bnb"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <BnbHome />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />
                  <Route
                    path="/send-money/ria"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <RiaHome />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />
                  <Route
                    path="/send-money/ria-completed"
                    element={
                      <AuthMiddleware>
                        <RequireAuth loginPath={"/login"}>
                          {" "}
                          <RiaCompleted />{" "}
                        </RequireAuth>
                      </AuthMiddleware>
                    }
                  />
                  <Route path="/send-money/bnb-completed"
                    element={ 
                      <AuthMiddleware>
                        <RequireAuth loginPath={"/login"}> 
                        <BnbCompleted />
                        </RequireAuth> 
                      </AuthMiddleware>
                    }
                  />
                  <Route
                    path="/paid-transactions"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <PaidTxnIndex />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />
                  <Route
                    path="/paid-transaction/:agentTxnId"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <PaidTxnShow />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />
                   <Route
                    path="/paid-transaction/create"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <SubmitPaidTransation />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />
                  <Route
                    path="/send-money/moneygram/form-free"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <MoneygramFormFreeSendHome />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />
                  {/* Route for account statements */}
                  <Route
                    path="/account-statements"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <AccountStatement />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />
                  {/* Route for user profile */}
                  <Route
                    path="/user-profile"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <UserProfile />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />
                  <Route
                    path="/verify-email"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <VerifyEmail />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />
                  <Route
                    path="/validate-code"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <ValidateCode />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />
                  <Route
                    path="/change-email"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <ChangeEmail />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />
                  {/* Route for passcodes */}
                  <Route
                    path="/create-passcode"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <CreatePasscode />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />
                  <Route
                    path="/activate-passcode"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <ActivatePasscode />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />

                  <Route
                    path="/change-password"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <ChangePassword />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />

                  <Route
                    path="/mobile-money"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <MobileMoney />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />
                  

                  <Route
                    path="/create-manual-cashin/:agent_company_id"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <CreateManualCashin />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />
                  <Route
                    path="/manual-cashin-details/:cicoId"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <ManualCashinDetails />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />

                  {/* Ecobank xpress */}
                  <Route
                    path="/xpress-cashin"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <EcobankXpress />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />
                  

                  <Route
                    path="/xpress-cashin-create"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <CreateXpressCashin />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />
                  <Route
                    path="/xpress-cashin-details/:id"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <EcobankXpressDetails />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />

                  <Route
                    path="/repayment-request/:id"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <RepaymentDetails />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />

                  <Route
                    path="/repayment-requests"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <RepaymentRequest />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />

                  <Route
                    path="/cash-pickup"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <CashPickupRepayment />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />

                  <Route
                    path="/wallet-repayment"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <WalletRepayment />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />

                  <Route
                    path="/bank-repayment"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <BankRepayment />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />

                  {/* Agent repayment */}
                  <Route
                    path="/agent-repayment"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <AgentRepayment />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />
                  <Route
                    path="/agent-repayment-details/:id"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <AgentRepaymentDetails />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />

                  {/* agent_topup */}
                  <Route
                    path="/agent-topup"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <AgentTopup />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />

                  <Route
                    path="/payMerchant"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <MerchantPaymentForm />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />

                  <Route
                    path="/merchantPaymentReport"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <MerchantPaymentReport />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />

                  <Route
                    path="/merchantPaymentReport/:paymentRef"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <MerchantPaymentShow />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />
                  {/* multiservices */}
                  <Route
                    path="/multiservices"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <Multiservice />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />

                  <Route
                    path="/create-multiservice"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <MultiserviceCreate />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />

                  <Route
                    path="/multiservices/:id"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <MultiserviceDetails />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />
                  {/* mtn cashin */}

                  <Route
                    path="/create-mtn-cashin/:agent_company_id"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <CreateMtnCashin />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />
                  {/* mtn cashout */}

                  <Route
                    path="/create-mtn-cashout/:agent_company_id"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <CreateMtnCashout />{" "}
                      </RequireAuth>
                      </AuthMiddleware>
                    }
                  />
                   <Route
                    path="/create-cashin/:agent_company_id"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <CreateCashin />{" "}
                        </RequireAuth>
                    </AuthMiddleware>
                    }
                    />
                  <Route
                    path="/summary-reports"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <SummaryReports />{" "}
                      </RequireAuth>
                    </AuthMiddleware>
                    }
                  />
                  <Route
                    path="/create-cashout/:agent_company_id"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <CreateCashout />{" "}
                        </RequireAuth>
                    </AuthMiddleware>
                    }
                  />
                    <Route
                    path="/reports/agent-commission"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <CommissionReport />{" "}
                      </RequireAuth>
                    </AuthMiddleware>
                    }
                  />
                  <Route
                    path="/reports/agent-commission/summary/:date"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <CommissionSummary />{" "}
                      </RequireAuth>
                    </AuthMiddleware>
                    }
                  />
                  <Route
                    path="/reports/agent-commission/detail/:date"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <CommissionDetail />{" "}
                      </RequireAuth>
                    </AuthMiddleware>
                    }
                  />
                  <Route
                    path="/reports/location-summary"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <LocationSummary />{" "}
                      </RequireAuth>
                    </AuthMiddleware>
                    }
                    />
          
                    <Route
                    path="/reports/company-reports"
                    element={
                      <AuthMiddleware>
                      <RequireAuth loginPath={"/login"}>
                        {" "}
                        <CompanyReports />{" "}
                      </RequireAuth>
                    </AuthMiddleware>
                    }
                  />
                </Routes>
              </section>
            </>
          )}
          {isToastVisible && <CustomToast />}
          {isLoading && <CustomLoading />}
        </>
      </Router>
    </div>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideLoading, showLoading } from "../../slices/loading-slice";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";
import { t } from "i18next";
import { Link, useNavigate } from "react-router-dom";
import { showToast } from "../../slices/toast-slice";
import { isEmptyObject } from "jquery";
import { useTranslation } from "react-i18next";
import { formatAmount, formatInDateTime } from "../../utils/helper";
import CustomDuration from "../CustomDuration";
import CustomPaginateOption from "../CustomPaginateOption";
import Swal from "sweetalert2";

const MobileMoney = () => {
    const dispatch = useDispatch();
    const authUser = useAuthUser();
    const [isCompany, setIsCompany] = useState(false);
    const [companies, setCompanies] = useState([]);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
    let cmpt = 0;
    const [useDetails, setUserDetails] = useState(null);
    const [cicoRequests, setCicoRequests] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [fromPage, setFromPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [fromElmt, setFromElmt] = useState(0);
    const [toElmt, setToElmt] = useState(0);
    const [recordPerPage, setRecordPerPage] = useState(10);
    const npage = Math.ceil(total/recordPerPage);
    const [duration, setDuration] = useState("1");
    const [types, setTypes] = useState([]);
    const [agentCompanies, setAgentCompanies] = useState([]);
    const [bnb,setBnb]= useState([])
    const MTN_CODE = "MTN";
    const OM_CODE = "OM";
    const AM_CODE = "AM";
    const [account, setAccount] = useState({
        duration: 1,
        status: '',
        from_date: '',
        to_date: '',
        agent_company_id: '',
        origin:"POS",
        type: '',
        agent_country_id: globalAuthUser?.location?.agent_country?.id,
        agent_location_id: globalAuthUser?.location?.id,
        per_page:10
    });
    const numbers = [];
    for (let i = 1; i <= npage; i++) {
        numbers.push(i);
    }
    useEffect(() => {
        dispatch(showLoading());
        var data = {
            'source':'pos',
            'type': 'cico',
        };
        const headers = {
            'Authorization': authUser().tokenType + ' ' + authUser().token
        };
        axios.post(process.env.REACT_APP_BNB_BASE_URL + '/api/portal/agentCompanies', data,{headers}
        ).then(res => {
            dispatch(hideLoading());
            if(res.data.status === 1){
                setIsCompany(true);
                console.log(res.data.data.length);
                if(isEmptyObject(res.data.data)){
                    dispatch( showToast(["danger", t("MOBILE_MONEY"), t("EMPTY_COMPANIES")]));
                }else{
                    setCompanies(res.data.data);
                }
            }else{
                dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
            }
        }).catch(error => {
            console.log(error);
            dispatch(hideLoading());
            dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
        });
    }, []);

    useEffect(() => {
        fetchBnb()
        dispatch(showLoading());
        const headers = {
            'Authorization': authUser().tokenType + ' ' + authUser().token
        };
        axios.post(process.env.REACT_APP_BNB_BASE_URL + '/api/portal/getInfoForCashinOutFilter', {},{headers}
        ).then(res => {
            dispatch(hideLoading());
            if(res.data.status === 1){
                setTypes(res.data.data.types);
                setAgentCompanies(res.data.data.agentCompanies);
            }else{
                dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
            }
        }).catch(error => {
            console.log(error);
            dispatch(hideLoading());
            dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
        });
    }, [])

    const fetchBnb = () => {
        const headers = {
            'Authorization': authUser().tokenType + ' ' + authUser().token
        };
        axios.post(process.env.REACT_APP_BNB_BASE_URL + '/api/portal/agentCompanies', {},{headers}
        ).then(res => {
            dispatch(hideLoading());
            console.log('res bnb',res)
            if(res.data.status === 1){
                const filteredCompanies = res.data.data.filter(company => company.company_id === 1);   
                setBnb(...filteredCompanies)
            }else{
                dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
            }
        }).catch(error => {
            dispatch(hideLoading());
            dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
        });
    }
    
    //Cummon function to fetch data
    function fetchData(url,currentPage, payload) {
        const headers = {
            'Authorization': authUser().tokenType + ' ' + authUser().token
        };
        const data = payload;
        dispatch(showLoading());
        axios.post(url, data, { headers })
        .then(response => {
            console.log("response");
            console.log(response.data.data.data);
            dispatch(hideLoading());
            setFromPage(response.data.data.from);
            setCicoRequests(response.data.data.data);
            setTotal(response.data.data.total);
            setFromElmt(response.data.data.from);
            setToElmt(response.data.data.to);
            setRecordPerPage(response.data.data.per_page);
            setCurrentPage(currentPage);
        })
        .catch(error => {
            console.error(error);
        });
    }

    useEffect(() => {
        axios({method: 'get', url: process.env.REACT_APP_BNB_BASE_URL + '/api/portal/userProfileWithData', 
              withCredentials: false,
              headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': authUser().tokenType + ' ' + authUser().token
              }
            }).then(res => {
              console.log(res);
              setUserDetails(res.data.data)

            })
    }, []);

    useEffect(() => {
        account.duration = duration
        var payload = account
        fetchData(process.env.REACT_APP_BNB_BASE_URL + '/api/portal/getCashinOutTxns',
        currentPage,
        payload);
    }, []);

    const currentAccounts = cicoRequests;
    var listAccounts = "";
    // console.log((statuses));
    listAccounts = currentAccounts?.map((item, index)=> {
            const {id,reference,customer,amount,currency,phoneNumber,type,statusText,company,date,
            status} = item;
        return (
            <tr key={id} >
                <td>{fromPage+index}</td>
                <td>{company}</td>
                <td>
                    <button type="button" className="btn btn-link" onClick={() => {
                            navigate('/manual-cashin-details/' + reference)
                        }}>
                    
                        {reference}
                    </button> 
                </td>
                <td>{customer}</td>
                <td>{phoneNumber}</td>
                <td>{type}</td>
                <td>{statusText != "" && statusText!= null ? (statusText === "New" ? "In Progress" : statusText) : status}</td>
                <td>{formatAmount(amount)} {currency}</td>
                <td>{formatInDateTime(date)}</td>
            </tr>
        )
    });
    //This function is used to change the page on pagination
    function changeCPage(page) {
        dispatch(showLoading());
        account.duration = duration
        var payload = account
        fetchData(`${process.env.REACT_APP_BNB_BASE_URL}/api/portal/getCashinOutTxns?page=${page}`,
        page,
        payload)
    }

    const handleInput = (e) => {
        e.persist();
        setAccount((prevAccount) => {
          const updatedAccount = { ...prevAccount, [e.target.name]: e.target.value };
          if (e.target.name === "per_page") {
            account.per_page = e.target.value;
            searchByParam(e);
          }
          if (e.target.name === 'from_date') {
            const from_date = e.target.value;
            updatedAccount.from_date = from_date;
            
            const fromDateObject = new Date(from_date);
            const toDateObject = new Date(
              fromDateObject.getFullYear(),
              fromDateObject.getMonth() + 1,
              fromDateObject.getDate()
            );
            const to_date = toDateObject.toISOString().split('T')[0];
            updatedAccount.to_date = to_date;
          }
          return updatedAccount;
        });
    };
      

    function handleDurationChange(event) {
        setDuration(event.target.value);
    }
    function handleDateInvalid(event) {
        event.target.setCustomValidity("Please select a date.");
    }

    //This is the function to reset all the param
    function resetSearch(event) {
        var myForm = document.getElementById('my-form');
        myForm.reset();
        setDuration(1);
        account.reference = "";
        dispatch(showLoading());
        event.preventDefault();
        var payload = account
        
        fetchData(process.env.REACT_APP_BNB_BASE_URL + '/api/portal/getCashinOutTxns',
        currentPage,
        payload);
    }

    function handleCashin(id){
        navigate('/create-mtn-cashin/'+id);
    }

    function handleCashout(id) {
        navigate('/create-mtn-cashout/'+id);
    }

    function handleBNBCashin(id){
        navigate('/create-cashin/'+id);
    }

    function handleBNBCashout(id){
        navigate('/create-cashout/'+id);
    }

    function handleOMCashin(id){
        navigate('/create-manual-cashin/'+id);
    }


    //This function is used to search by param like duration 
    function searchByParam(event) {
        dispatch(showLoading());
        event.preventDefault();
        account.duration = duration
        var payload = account
        if(duration === "6"){
            const from_date_error = document.getElementById('from_date_error');
            const to_date_error = document.getElementById('to_date_error');
            if (account.from_date === "") {
                from_date_error.textContent = 'Please enter a valid date';
                dispatch(hideLoading());
                return ;
            } else {
                from_date_error.textContent = '';
            }
            if (account.to_date === "") {
                to_date_error.textContent = 'Please enter a valid date';
                dispatch(hideLoading());
                return ;
            } else {
                to_date_error.textContent = '';
            }
        }
        
        fetchData(process.env.REACT_APP_BNB_BASE_URL + '/api/portal/getCashinOutTxns',
        currentPage,
        payload);
    }

    const handleAlert = (companyId) => {
        let html = `
            <button class="btn btn-success col-12" id="cashInButton">
                ${t("CASHIN")}
            </button> <br><br>
            <button class="btn btn-danger col-12" id="cashOutButton">
                ${t('CASHOUT')}
            </button>
        `;
        const swalInstance = Swal.fire({
            title: 'MTN MoMo',
            showCancelButton: false,
            showConfirmButton: false,
            html: html
        });

        const cashInButton = document.querySelector(".btn-success");
        const cashOutButton = document.querySelector(".btn-danger");
        cashInButton.addEventListener('click', () => {
            handleCashin(companyId);
            swalInstance.close(); 
        });

        cashOutButton.addEventListener('click', () => {
            handleCashout(companyId);
            swalInstance.close(); 
        });
    }

    const handleBnbAlert = (companyId) => {
        let html = `
            <button class="btn btn-success col-12" id="cashInButton">
                ${t("CASHIN")}
            </button> <br><br>
            <button class="btn btn-danger col-12" id="cashOutButton">
                ${t('CASHOUT')}
            </button>
        `;
        const swalInstance = Swal.fire({
            title: 'BNB',
            showCancelButton: false,
            showConfirmButton: false,
            html: html
        });

        const cashInButton = document.querySelector(".btn-success");
        const cashOutButton = document.querySelector(".btn-danger");
        cashInButton.addEventListener('click', () => {
            handleBNBCashin(companyId);
            swalInstance.close(); 
        });

        cashOutButton.addEventListener('click', () => {
            handleBNBCashout(companyId);
            swalInstance.close(); 
        });


        
    }

    const handleOMRequest = (companyId, companyCode) => {
        let html = `
            <button class="btn btn-success col-12" id="omCashin">
                ${t("CASHIN")}
            </button> <br><br>
            <button class="btn btn-danger col-12" id="omCashout">
                ${t('CASHOUT')}
            </button>
        `;
        const swalInstance = Swal.fire({
            title: companyCode == OM_CODE ? 'Orange Money' : 'Afrimoney',
            showCancelButton: false,
            showConfirmButton: false,
            html: html
        });

        const omCashin = document.querySelector(".btn-success");
        const omCashout = document.querySelector(".btn-danger");
        omCashin.addEventListener('click', () => {
            handleOMCashin(companyId);
            swalInstance.close(); 
        });

        omCashout.addEventListener('click', () => {
            handleCashout(companyId); //this can be used for all cashouts related to mpp
            swalInstance.close(); 
        });
    }
    
    return (
        <React.Fragment>
            <div style={{ padding: "20px" }}>
                <div className="card">
                    <div className="card-header row">
                        <h5 className="col-sm-12">{t('SELECT_A_MOBILE_MONEY_COMPANY_TO_DO_A_TRANSACTION')}</h5>
                    </div>
                    
                    <div className="card-body">
                    <form id="my-form">
                        <div className="row">
                            {/* Rendering BNB card */}
                                <div className="col-sm-3 col-md-3">
                                    <div className="card" onClick={() => handleBnbAlert(bnb.company_id)} style={{height: "200px", width: "100%", textAlign: "center", cursor: "pointer"}}>
                                        <div style={{height: "80%", width: "90%", margin: "auto", textAlign: "center"}}>
                                            <img src={process.env.REACT_APP_BNB_BASE_URL + '/' + bnb.company_logo} style={{maxHeight: "100%", maxWidth: "100%"}} alt="..." />
                                        </div>
                                        <div className="card-body">
                                            <p className="card-text text-center">BNB</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Rendering other company cards */}
                                {
                                    companies.length === 0 ? <p>{t('EMPTY_COMPANIES')}</p> :
                                    isCompany && 
                                    companies.map((company,index) => {
                                        return company.company_code == MTN_CODE && useDetails?.location?.enable_mtnMoMo_cico 
                                        ?   (
                                            <div className="col-sm-3 col-md-3" key={index}>
                                                {/* <Link to={`/create-mtn-cashin/${company.company_id}`}> */}
                                                    <div className="card" onClick={() => handleAlert(company.company_id)} style={{  height: "200px", width: "100%", textAlign: "center", cursor: "pointer"}}>
                                                        <div style={{height: "80%", width: "90%", margin: "auto", textAlign: "center"}} >
                                                        <img src={process.env.REACT_APP_BNB_BASE_URL +'/'+company.company_logo} style={{maxHeight: "100%", maxWidth: "100%"}}  alt="..." />
                                                        </div> 
                                                        
                                                        <div className="card-body">
                                                            <p className="card-text text-center">{company.company_name}</p>
                                                        </div>
                                                    </div>
                                                {/* </Link> */}
                                            </div>
                                        )  : 
                                        (
                                            company.company_code != MTN_CODE ? (
                                                company.company_code == OM_CODE && useDetails?.location?.enable_orangeMoney_cico ?
                                                <div className="col-sm-3 col-md-3" key={index}>
                                                    {/* <Link to={`/create-manual-cashin/${company.company_id}`}> */}
                                                        <div className="card" onClick={() => handleOMRequest(company.company_id, OM_CODE)} style={{  height: "200px", width: "100%", textAlign: "center", cursor: "pointer"}}>
                                                            <div style={{height: "80%", width: "90%", margin: "auto", textAlign: "center"}} >
                                                            <img src={process.env.REACT_APP_BNB_BASE_URL +'/'+company.company_logo} style={{maxHeight: "100%", maxWidth: "100%"}}  alt="..." />
                                                            </div>
                                                            
                                                            <div className="card-body">
                                                                <p className="card-text text-center">{company.company_name}</p>
                                                            </div>
                                                        </div>
                                                    {/* </Link> */}
                                                </div>
                                                : 
                                                company.company_code == AM_CODE && useDetails?.location?.enable_afrimoney_cico ?
                                                <div className="col-sm-3 col-md-3" key={index}>
                                                    <div className="card" onClick={() => handleOMRequest(company.company_id, AM_CODE)} style={{  height: "200px", width: "100%", textAlign: "center", cursor: "pointer"}}>
                                                        <div style={{height: "80%", width: "90%", margin: "auto", textAlign: "center"}} >
                                                        <img src={process.env.REACT_APP_BNB_BASE_URL +'/'+company.company_logo} style={{maxHeight: "100%", maxWidth: "100%"}}  alt="..." />
                                                        </div>
                                                        
                                                        <div className="card-body">
                                                            <p className="card-text text-center">{company.company_name}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="col-sm-3 col-md-3" key={index}>
                                                    <Link to={`/create-manual-cashin/${company.company_id}`}>
                                                        <div className="card" style={{  height: "200px", width: "100%", textAlign: "center"}}>
                                                            <div style={{height: "80%", width: "90%", margin: "auto", textAlign: "center"}} >
                                                            <img src={process.env.REACT_APP_BNB_BASE_URL +'/'+company.company_logo} style={{maxHeight: "100%", maxWidth: "100%"}}  alt="..." />
                                                            </div>
                                                            
                                                            <div className="card-body">
                                                                <p className="card-text text-center">{company.company_name}</p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            ) : ''
                                        )
                                    })
                                }
                                {
                                    globalAuthUser?.location?.agent_country?.enable_xpress_cashin == 1 && (
                                        // Add ecobank express
                                        <div className="col-sm-3 col-md-3" key="ecobank">
                                            <Link to={'/xpress-cashin-create'}>
                                                <div className="card" style={{  height: "200px", width: "100%", textAlign: "center"}}>
                                                    <div style={{height: "80%", width: "90%", margin: "auto", textAlign: "center"}} >
                                                    <img src={process.env.REACT_APP_BNB_BASE_URL +'/'+'img/ecobank.png'} style={{maxHeight: "100%", maxWidth: "100%"}}  alt="..." />
                                                    </div>
                                                    
                                                    <div className="card-body">
                                                        <p className="card-text text-center">{t('ECOBANK_XPRESS')}</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                }
                        </div>
                    </form>

                    </div>
                </div> 
            </div>
            <div style={{ padding: "20px" }}>
                <div className="card">
                    <div className="card-header row">
                        <h5 className="col-sm-4"> </h5>
                        <h4 className="col-sm-4">
                            <p> <span style={{ fontSize: '23px', marginRight: '10px'}}>Balance: </span> <span style={{ fontSize: '23px', fontWeight: 'bold'}}>
                                { Number(useDetails?.location?.balance).toLocaleString('en-US',{minimumFractionDigits: 2,maximumFractionDigits: 2}) + ' ' + useDetails?.location?.currency }
                                </span> 
                            </p>
                        </h4>
                    </div>
                    
                    <div className="card-body">
                        <form id="my-form">
                            <div className="form-group row justify-content-md-center">
                                <div className="col-md-3">
                                    <label className=""  htmlFor="type">{t('TRANSACTION_TYPE')}</label>
                                    <select className="form-select" onChange={handleInput} name="type" id="type" aria-label="Default select example">
                                        <option value={""}>{t('SELECT_A_TYPE')}</option>
                                        {
                                            Object.keys(types).map((key, index) => (
                                                <option key={index} value={key}>
                                                {types[key]}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="col-md-3">
                                    <label className=""  htmlFor="agent_company_id">{t('COMPANY')}</label>
                                    <select className="form-select" onChange={handleInput} name="agent_company_id" id="agent_company_id" aria-label="Default select example">
                                        <option defaultValue={""} value={""}>{t('SELECT_A_COMPANY')}</option>
                                        {
                                            Object.keys(agentCompanies).map((key, index) => (
                                                <option key={index} value={key}>
                                                {agentCompanies[key]}
                                                </option>
                                            ))
                                        }
                                        <option value="0">{t('ECOBANK_XPRESS')}</option>
                                    </select>
                                </div>
                                <CustomDuration 
                                    duration={duration} 
                                    handleDurationChange={handleDurationChange} 
                                    handleDateInvalid={handleDateInvalid}
                                    handleInput={handleInput}
                                    from_date={account.from_date}
                                    to_date={account.to_date}
                                />
                            </div>
                            
                            <div className="form-group row justify-content-md-center mt-3">
                                <button type="submit" onClick={searchByParam} className="col-sm-2 btn btn-primary" id="apply-filter-paid" data-count="0">{t('SEARCH')}</button>
                                &nbsp;
                                <button type="reset" onClick={resetSearch} className="col-sm-2 btn btn-secondary" id="apply-filter-clear-paid">{t('CLEAR_SEARCH')}</button>
                            </div>
                            <div className="per_page m-2">
                               <CustomPaginateOption 
                                    per_page={account.per_page}
                                    handleInput={handleInput}
                                    total={total}
                                /> 
                            </div>
                        </form>
                        <table id="datatable" className="table table-bordered grid-table responsive nowrap w-100">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{t('COMPANY')}</th>
                                    <th>{t('REFERENCE')}</th>
                                    <th>{t('CUSTOMER')}</th>
                                    <th>{t('PHONE_NUMBER')}</th>
                                    <th>{t('TYPE')}</th>
                                    <th>{t('STATUS')}</th>
                                    <th>{t('AMOUNT')}</th>
                                    <th>{t('DATE')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listAccounts}
                            </tbody>
                            
                        </table>
                    </div>
                    <div className="card-footer">
                        
                        {
                            numbers.length > 1 ? (
                                <>
                                    <div className="pagination" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div className="left-info">
                                            <span>
                                            {t('SHOWING')} {fromElmt} {t('TO')} {toElmt} {t('OF')} {total} {t('ENTRIES')}
                                            </span>
                                        </div>
                                        <div className="float-end">
                                            <nav aria-label="Page navigation example">
                                                <ul className="pagination" style={{ display: 'flex', listStyle: 'none', margin: 0, padding: 0 }}>
                                                    <li className="page-item">
                                                        <button type="button" onClick={() => changeCPage(currentPage-1)} className={`page-link ${currentPage === 1 ? 'disabled' : ''}`} aria-label="Previous">
                                                            <span aria-hidden="true">&laquo;</span>
                                                        </button>
                                                    </li>
                                                    
                                                    {numbers.map((page, index) => (
                                                    <li className={`page-item ${currentPage === page ? 'active' : ''}`} key={index}>
                                                        {
                                                            (
                                                                page === 1 || 
                                                                page === numbers.length || 
                                                                currentPage === page || 
                                                                currentPage+1 === page || 
                                                                currentPage+2 === page ||
                                                                currentPage-1 === page || 
                                                                currentPage-2 === page )  ? 
                                                                (<button type='button' onClick={() => changeCPage(page)} className="page-link">
                                                                    {
                                                                        page
                                                                    }
                                                                </button>) 
                                                            : ((cmpt === 1 && (page === currentPage+3)) ? (
                                                                <button className="page-link">
                                                                  {(() => {
                                                                    cmpt++;
                                                                    return '...';
                                                                  })()}
                                                                </button>
                                                              ) : ((cmpt === 0) ? (
                                                                <button className="page-link">
                                                                  {(() => {
                                                                    cmpt++;
                                                                    return '...';
                                                                  })()}
                                                                </button>
                                                              ) : null))
                                                        }
                                                    </li>
                                                    ))}
                                                    <li className="page-item">
                                                        <button type="button" onClick={() => changeCPage(currentPage+1)} className={`page-link ${currentPage === numbers.length ? 'disabled' : ''}`} aria-label="Previous">
                                                            <span aria-hidden="true">&raquo;</span>
                                                        </button>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>

                                </>
                            ) : (
                                <></>
                            )
                        }
                    </div>
                </div> 
            </div>
        </React.Fragment>
    );
}

export default MobileMoney;